import React from 'react'
import Layout from '../components/layout'

import profileRex from '../assets/images/profile-rex.png'

const NotFoundPage = () => (
  <Layout>
    <div className="not-found-container">
      <img className="not-found-container__avatar" src={profileRex} alt="Rex character"/>
      <h1 className="not-found-container__title">Oh no!</h1>
      <p className="not-found-container__subtitle">Looks like you've explored too far! This space hasn't been discovered yet.</p>
    </div>
  </Layout>
)

export default NotFoundPage
